import React from "react"

import "./style.scss"

const Iframe = props => (
  <div className={`iframe-div ${props.parentclass}`}>
    <iframe
      style={{ width: "100%", height: "100%", border: 0 }}
      src={props.src}
      {...props}
    />
  </div>
)

export default Iframe
