import React, { useState } from "react"
import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"

import "./style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialIcons from "../components/socialIcons"
import Songkick from "../components/songkick"
import HeroMedia from "../components/heroMedia"
import Iframe from "../components/iframe"
import YoutubePlayer from "../components/youtubePlayer"
import FormModal from "../components/formModal"

const IndexPage = ({ data }) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const heroDoc = data.allPrismicHero.nodes[0].data || null
  const quoteDoc = data.allPrismicQuote.nodes[0].data || null
  const aboutDoc = data.allPrismicAbout.nodes[0].data || null
  const musicDoc = data.allPrismicMusic.nodes[0].data || null
  const tourDoc = data.allPrismicTour.nodes[0].data || null
  const imageDoc = data.allPrismicImage.nodes[0].data || null
  const videoDoc = data.allPrismicYoutubeVideo.nodes[0].data || null
  const contactDoc = data.allPrismicContact.nodes[0].data || null

  return (
    <>
      <Layout>
        <div id="home" />
        <SEO title="Let Spin" />
        {showModal && <FormModal toggleModal={toggleModal} />}
        <div className="format-div">
          {heroDoc.visible && (
            <div
              className="hero-div"
              style={{
                backgroundImage: `url(${heroDoc.hero_background_image.url})`,
              }}
            >
              <div className="cta-div">
                <h1>{heroDoc.hero_title}</h1>
                <h2>{heroDoc.hero_subtitle}</h2>
                <a
                  href={heroDoc.button_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="cta-button">{heroDoc.button_text}</button>
                </a>
              </div>
              <HeroMedia />
              <a
                href={heroDoc.button_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="cta-small-button">
                  {heroDoc.button_text}
                </button>
              </a>
            </div>
          )}

          {quoteDoc.visible && (
            <div className="press-div">
              <h3>“{quoteDoc.quote}”</h3>
              <h4>{quoteDoc.quote_author}</h4>
            </div>
          )}

          {aboutDoc.visible && (
            <div id="about">
              <div className="about-div">
                <div className="inner-about-div">
                  <h1 className="div-header">{aboutDoc.about_title}</h1>
                  <div className="inner-about-header-div">
                    <PrismicRichText field={aboutDoc.about_text.richText} />
                    <button onClick={toggleModal}>
                      {aboutDoc.button_text}
                    </button>
                  </div>
                </div>
                <img
                  src={aboutDoc.about_image.url}
                  alt={aboutDoc.about_image.alt}
                />
              </div>
            </div>
          )}

          {musicDoc.visible && (
            <div id="music">
              <div className="music-div">
                <h1 className="div-header">{musicDoc.music_title}</h1>
                <div className="inner-top-music-div">
                  <div className="top-music-left-div">
                    <img
                      src={musicDoc.music_image.url}
                      alt={musicDoc.music_image.alt}
                    />
                  </div>
                  <div className="top-music-right-div">
                    <h2>
                      {musicDoc.music_text_header} -{" "}
                      {musicDoc.music_text_subheader}
                    </h2>
                    <div className="top-music-right-inner-div">
                      <PrismicRichText field={musicDoc.music_text.richText} />
                    </div>
                  </div>
                </div>

                <div className="inner-bottom-music-div">
                  {musicDoc.bandcampembeds.map(
                    (embed, index) =>
                      embed.identifier.length > 10 && (
                        <div className="bandcamp-iframe-div" key={index}>
                          <Iframe
                            parentclass={"bandcamp-iframe"}
                            src={`https://bandcamp.com/EmbeddedPlayer/${embed.identifier}/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/transparent=true/`}
                            seamless
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          {tourDoc.visible && (
            <div id="tour">
              <div className="tour-div">
                <h1 className="div-header">{tourDoc.tour_title}</h1>
                <div className="inner-tour-div">
                  <div className="inner-tour-left-div">
                    {tourDoc.tour_image_toggle ? (
                      <img
                        className="tour-image"
                        src={tourDoc.tour_image.url}
                        alt={tourDoc.tour_image.alt}
                      />
                    ) : (
                      <PrismicRichText field={tourDoc.tour_text.richText} />
                    )}
                    <button onClick={toggleModal}>{tourDoc.button_text}</button>
                  </div>
                  <Songkick />
                </div>
              </div>
            </div>
          )}

          {imageDoc.visible && (
            <div id="image">
              <img
                className="image-section"
                src={imageDoc.image.url}
                alt={imageDoc.image.alt}
              />
            </div>
          )}

          {videoDoc.visible && (
            <div id="video">
              <div className="video-div">
                <h1 className="div-header">VIDEOS</h1>
                <div className="inner-video-div">
                  {videoDoc.videos.map((video, index) => (
                    <div className="video-player-div" key={index}>
                      <YoutubePlayer
                        iframeSrc={video.video_link}
                        imageSrc={video.image.url}
                        imageAlt={video.image.alt}
                      />
                      <p className="video-player-sub">{video.video_title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {contactDoc.visible && (
            <>
              <div className="contact-press-div__small-screen contact-wrapper">
                <div className="contact-quote-div">
                  <h3 className="contact-quote">“{contactDoc.quote_1}”</h3>
                  <h4 className="contact-quote-author">
                    {" "}
                    - {contactDoc.quote_1_author}
                  </h4>
                </div>

                <div className="contact-quote-div middle">
                  <h3 className="contact-quote">“{contactDoc.quote_2}”</h3>
                  <h4 className="contact-quote-author">
                    {" "}
                    - {contactDoc.quote_2_author}
                  </h4>
                </div>

                <div className="contact-quote-div">
                  <h3 className="contact-quote">“{contactDoc.quote_3}”</h3>
                  <h4 className="contact-quote-author">
                    {" "}
                    - {contactDoc.quote_3_author}
                  </h4>
                </div>
              </div>

              <div className="contact-wrapper" id="contact">
                <div className="contact-div">
                  <div className="inner-contact-div">
                    <h1 className="div-header">{contactDoc.contact_title}</h1>
                    <div className="inner-contact-header-div">
                      <h2>{contactDoc.contact_header_1}</h2>
                      <div className="contact-text-div">
                        <PrismicRichText
                          field={contactDoc.contact_text.richText}
                        />
                      </div>
                      <h2>Join our mailing list</h2>
                      <div className="contact-text-div">
                        <p>
                          Subscribe to our newsletter{" "}
                          <a onClick={toggleModal}>here</a>.
                        </p>
                      </div>
                      <h2>{contactDoc.contact_header_2}</h2>
                      <div className="icon-contact-div">
                        <SocialIcons />
                      </div>
                    </div>
                  </div>

                  <div className="contact-press-div">
                    <div className="contact-quote-div">
                      <h3 className="contact-quote">“{contactDoc.quote_1}”</h3>
                      <h4 className="contact-quote-author">
                        {" "}
                        - {contactDoc.quote_1_author}
                      </h4>
                    </div>

                    <div className="contact-quote-div middle">
                      <h3 className="contact-quote">“{contactDoc.quote_2}”</h3>
                      <h4 className="contact-quote-author">
                        {" "}
                        - {contactDoc.quote_2_author}
                      </h4>
                    </div>

                    <div className="contact-quote-div">
                      <h3 className="contact-quote">“{contactDoc.quote_3}”</h3>
                      <h4 className="contact-quote-author">
                        {" "}
                        - {contactDoc.quote_3_author}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    allPrismicHero {
      nodes {
        data {
          visible
          hero_background_image {
            url
          }
          hero_title
          hero_subtitle
          button_text
          button_link
        }
      }
    }

    allPrismicQuote {
      nodes {
        data {
          visible
          quote
          quote_author
        }
      }
    }

    allPrismicAbout {
      nodes {
        data {
          visible
          about_title
          about_text {
            text
            richText
          }
          button_text
          about_image {
            url
            alt
          }
        }
      }
    }

    allPrismicMusic {
      nodes {
        data {
          visible
          music_title
          music_text_header
          music_text_subheader
          music_text {
            richText
          }
          music_image {
            url
            alt
          }
          bandcampembeds {
            identifier
          }
        }
      }
    }

    allPrismicTour {
      nodes {
        data {
          visible
          tour_title
          tour_text {
            richText
          }
          tour_image_toggle
          tour_image {
            url
            alt
          }
          button_text
        }
      }
    }

    allPrismicImage {
      nodes {
        data {
          visible
          image {
            url
            alt
          }
        }
      }
    }

    allPrismicYoutubeVideo {
      nodes {
        data {
          visible
          videos {
            image {
              url
              alt
            }
            video_link
            video_title
          }
        }
      }
    }

    allPrismicContact {
      nodes {
        data {
          visible
          contact_title
          contact_header_1
          contact_text {
            richText
          }
          contact_header_2
          quote_1
          quote_1_author
          quote_2
          quote_2_author
          quote_3
          quote_3_author
        }
      }
    }
  }
`
