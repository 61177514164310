import React from "react"
import { useState } from "react"

import "./style.scss"

const YoutubePlayer = props => {
  const [autoplay, setAutoplay] = useState("?autoplay=0")
  const [hidden, setHidden] = useState()

  let onClick = () => {
    setHidden("hidden")
    setAutoplay("?autoplay=1")
  }

  const noSuggestedVideos = "&rel=0"
  let playButton = `play-button clickable ${hidden}`
  let videoImg = `video-img clickable ${hidden}`

  return (
    <div className="iframe-div youtube-player-iframe">
      <button className={playButton} onClick={onClick}>
        <img src={"play-button.svg"} alt="play arrow" />
      </button>
      <img
        className={videoImg}
        src={props.imageSrc}
        alt={props.imageAlt}
        onClick={onClick}
      />
      <iframe
        style={{ width: "100%", height: "100%", border: 0 }}
        src={props.iframeSrc + autoplay + noSuggestedVideos}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowFullScreen"
        title={props.iframeSrc}
      />
    </div>
  )
}

export default YoutubePlayer
