import React from "react"
import { useState } from "react"
import { StaticQuery, graphql } from "gatsby"

import "./style.scss"

const heroMediaQuery = graphql`
  query {
    allPrismicHero {
      nodes {
        data {
          video_link
          hero_image {
            url
            alt
          }
          clickable
        }
      }
    }
  }
`

const RenderHeroMedia = ({ data }) => {
  const heroMediaDoc = data.allPrismicHero.nodes[0].data
  let clickable = heroMediaDoc.clickable

  const [autoplay, setAutoplay] = useState("?autoplay=0")
  const [hidden, setHidden] = useState()

  let onClick = () => {
    if (clickable) {
      setHidden("hidden")
      setAutoplay("?autoplay=1")
    }
  }

  let heroButton = clickable ? `hero-button clickable ${hidden}` : "hero-button"
  let heroImg = clickable ? `hero-img clickable ${hidden}` : "hero-img"
  let heroIframe = clickable ? "hero-iframe" : "hero-iframe hidden"

  return (
    <div className="iframe-div youtube-iframe">
      <button className={heroButton} onClick={onClick}>
        <img src={"play-button.svg"} alt="play arrow" />
      </button>
      <img
        className={heroImg}
        src={heroMediaDoc.hero_image.url}
        alt={heroMediaDoc.hero_image.alt}
        onClick={onClick}
      />
      <iframe
        className={heroIframe}
        style={{ width: "100%", height: "100%", border: 0 }}
        src={heroMediaDoc.video_link + autoplay}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowFullScreen"
      />
    </div>
  )
}

const HeroMedia = () => (
  <>
    <StaticQuery
      query={heroMediaQuery}
      render={data => <RenderHeroMedia data={data} />}
    />
  </>
)

export default HeroMedia
