import React from "react"
import { useEffect } from "react"

import colors from "../../utils/colors"
import "./style.scss"

const Songkick = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "//widget.songkick.com/5486788/widget.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <a
        href="https://www.songkick.com/artists/5486788"
        className="songkick-widget"
        data-theme="dark"
        data-detect-style="true"
        data-font-color={colors.white}
        data-font-family="Source Sans Pro"
        data-background-color="transparent"
        data-locale="en"
        aria-label="songkick-widget"
      ></a>
    </>
  )
}

export default Songkick
