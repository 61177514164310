import React, { useState, useEffect } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import "./style.scss"

const FormModal = props => {
  const [email, setEmail] = useState("")
  const [enableButton, setEnableButton] = useState(false)
  const [showSadMessage, setShowSadMessage] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [messageText, setMessageText] = useState("")

  const emailRegex = new RegExp(/.+[@].+[.].+/)

  useEffect(() => {
    const checkEmail = emailRegex.test(email)
    setEnableButton(checkEmail)
  }, [email])

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        if (data.result === "success") {
          setShowSuccessMessage(true)
          setMessageText(data.msg)
        } else {
          setShowSadMessage(true)
          setMessageText(data.msg)
        }
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const stopPropagation = e => {
    e.stopPropagation()
  }

  return (
    <div className="formmodal" onClick={props.toggleModal}>
      {!showSuccessMessage && !showSadMessage && (
        <form
          onSubmit={handleSubmit}
          onClick={stopPropagation}
        >
          <h2>Subscribe to our Newsletter</h2>

          <>
            <div className="form-div">
              <input
                placeholder="myemail@email.com"
                name="email"
                type="text"
                onChange={handleEmailChange}
              />
              <button
                type="submit"
                onClick={stopPropagation}
                disabled={!enableButton}
                className={!enableButton ? "button-disabled" : "button-enabled"}
              >
                Subscribe
              </button>
            </div>
            <p>
              You can unsubscribe at any time by clicking the link in the footer
              of our emails.
            </p>
            <p>
              For information about our privacy practices, please visit our
              Terms page.
            </p>
          </>
        </form>
      )}
      {showSuccessMessage && (
        <div className="resultDiv">
          <h2>Thanks for subscribing!</h2>
          <p>{messageText}</p>
        </div>
      )}
      {showSadMessage && (
        <div className="resultDiv">
          <p>Oh no, something went wrong. Please try again in a bit. Ta!</p>
        </div>
      )}
    </div>
  )
}

export default FormModal
